/* eslint-disable react/function-component-definition */
/* eslint-disable no-undef */
import { useAtom } from 'jotai';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { tenantData } from '../../jotaiStore/tenants';

const TenantLogo = ({ style }) => {
  const [getTenant] = useAtom(tenantData);
  return (
    <Link to="/">
      <img style={style} src={getTenant?.data?.logo} alt="" />
    </Link>
  );
};

TenantLogo.propTypes = {
  style: PropTypes.object,
};

export default TenantLogo;
