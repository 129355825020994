/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, lazy, useCallback } from 'react';
import { Provider, useSelector } from 'react-redux';
import { useAtom } from 'jotai';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import { tenantData } from './jotaiStore/tenants';
import { getTenantData } from './services/auth';
import DonorCheckIn from './container/donorCheckIn';

const NotFound = lazy(() => import('./container/pages/404'));
// const DonorCheckin = lazy(() => import('./container/donorCheckIn'));
const SocketEventScreen = lazy(() => import('./components/SocketEventScreen'));

const { theme } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);
  const [, setGetTenant] = useAtom(tenantData);
  const getTenantDataFun = useCallback(() => {
    // const isTenantData = typeof window !== 'undefined' && localStorage.getItem('tenantData');

    // if (isTenantData) {
    //   return setGetTenant({
    //     loading: false,
    //     data: JSON.parse(isTenantData),
    //   });
    // }
    // setGetTenant({
    //   loading: true,
    // });
    getTenantData({})
      .then((res) => {
        localStorage.setItem('tenantData', JSON.stringify(res?.data));
        document.title = res?.data?.organizationName;
        const link = document.querySelector("link[rel*='icon']");
        link.sizes = '64x64';
        link.href = res?.data?.logo;
        setGetTenant({
          loading: false,
          data: res?.data,
        });
      })
      .catch(() => {
        setGetTenant({
          loading: false,
        });
      });
  }, []);
  useEffect(() => {
    getTenantDataFun();
  }, [getTenantDataFun]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <SocketEventScreen />
        <Router basename={process.env.PUBLIC_URL}>
          {/* <Routes>
            <Route exact path="/donor/checkin" element={<DonorCheckIn />} />
          </Routes> */}
          {!isLoggedIn ? (
            <Routes>
              <Route path="/donor/checkin" element={<DonorCheckIn />} />
              <Route path="/donor/checkin/network" element={<DonorCheckIn clientTypeInit="network-client" />} />
              <Route path="/donor/checkin/house" element={<DonorCheckIn clientTypeInit="house-client" />} />
              <Route path="/*" element={<Auth />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/donor/checkin" element={<DonorCheckIn />} />
              <Route path="/donor/checkin/network" element={<DonorCheckIn clientTypeInit="network-client" />} />
              <Route path="/donor/checkin/house" element={<DonorCheckIn clientTypeInit="house-client" />} />
              <Route path="/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Routes>
              <Route path="/" element={<Navigate to="/" />} />
            </Routes>
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
