const { defaults } = require('./defaults');

export const poolEndpoints = {
  createPool: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/random/pool/',
    },
  },
  getPool: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/pool/',
    },
  },
  singlePool: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/pool/:id',
    },
  },
  clientList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/pool/:id/client',
    },
  },
  driverList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: '/random/pool/:id/driver',
    },
  },
  updatePool: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/random/pool/:id',
    },
  },
  addClientToPool: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/random/pool/:poolId/add-client',
    },
  },
  removeClientFromPool: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/random/pool/:poolId/delete-client/:clientId',
    },
  },
  deletePool: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/random/pool/:id',
    },
  },
  deleteDriverFromPool: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: '/random/pool/driver/:id',
    },
  },
  updateDriverPool: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: '/random/pool/driver',
    },
  },
  sendEmail: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: '/random/pool/sendEmail',
    },
  },
};
