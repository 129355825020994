import React, { useLayoutEffect } from 'react';
// import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { TopMenuStyle } from './Style';
import { tenantData } from '../jotaiStore/tenants';

function TopMenu() {
  const [getTenant] = useAtom(tenantData);
  const isNotWholesaler = getTenant?.data?.type !== 'wholesale';
  const path = '/';

  useLayoutEffect(() => {
    const active = document.querySelector('.hexadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const addParentActive = (event) => {
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };
  // const currentUser = useSelector((payload) => payload.currentUser.data);
  return (
    <TopMenuStyle>
      <div className="hexadash-top-menu ltr:pl-[20px] rtl:pr-[20px] xl:ltr:pl-[10px] xl:rtl:pr-[10px]">
        <ul>
          <li className="">
            <Link to="/" className="parent">
              Dashboard
            </Link>
          </li>
          {isNotWholesaler && (
            <li className="has-subMenu">
              <Link to="#" className="parent">
                Donor
              </Link>
              <ul className="subMenu">
                <li className="">
                  <Link to={`${path}donor/lobby/waiting`}>Waiting in lobby</Link>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}donor/house-client`}>
                    In-House Check-ins
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}donor/network-client`}>
                    Network Check-ins
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
          <li className="">
            <Link to="/clients" className="parent">
              Clients
            </Link>
          </li>
          <li className="">
            <Link to="/driver" className="parent">
              Driver
            </Link>
          </li>
          <li className="">
            <Link to="/pool" className="parent" rel="noopener noreferrer">
              Random Pool
            </Link>
          </li>
          {isNotWholesaler && (
            <li className="has-subMenu">
              <Link to="#" className="parent">
                Sap
              </Link>
              <ul className="subMenu">
                <li className="">
                  <Link to="/sap" className="parent">
                    List
                  </Link>
                </li>
                <li className="">
                  <Link to="/cases" className="parent">
                    Cases
                  </Link>
                </li>
              </ul>
            </li>
          )}
          <li className="">
            <Link to="/driver/test/result" className="parent ">
              Pending result
            </Link>
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenu;
