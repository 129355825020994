import { callApi } from '../utility/apiUtils';
import { donorEndPoints } from '../endpoints/donor';

export const addDonorService = ({ body }) => callApi({ uriEndPoint: donorEndPoints.createDonor.v1, body });
export const getDonorService = ({ query }) => callApi({ uriEndPoint: donorEndPoints.getDonors.v1, query });
export const exportDonorService = ({ query }) =>
  callApi({
    uriEndPoint: donorEndPoints.exportDonor.v1,
    query,
    headerProps: { responseType: 'arraybuffer' },
  });
export const updateDonorService = ({ body, pathParams }) =>
  callApi({ uriEndPoint: donorEndPoints.updateDonor.v1, body, pathParams });
export const getSingleDonorDetails = ({ pathParams }) =>
  callApi({ uriEndPoint: donorEndPoints.getSingleDonorDetails.v1, pathParams });
export const updateBasicDetailsService = ({ pathParams, body }) =>
  callApi({ uriEndPoint: donorEndPoints.updateBasicDetails.v1, pathParams, body });
export const driverDetailFromLicenseNumber = ({ pathParams, query }) =>
  callApi({ uriEndPoint: donorEndPoints.driverDetailFromLicenseNumber.v1, pathParams, query }).then((res) => {
    return res;
  });
export const deleteDonor = ({ pathParams }) => callApi({ uriEndPoint: donorEndPoints.deleteDonor.v1, pathParams });

export const createDriver = ({ body }) => callApi({ uriEndPoint: donorEndPoints.createDriver.v1, body });
