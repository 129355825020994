import { poolEndpoints } from '../endpoints/pool';
import { callApi } from '../utility/apiUtils';

export const addPool = ({ body }) => callApi({ uriEndPoint: poolEndpoints.createPool.v1, body });

export const updatePoolService = ({ body, pathParams }) =>
  callApi({ uriEndPoint: poolEndpoints.updatePool.v1, body, pathParams });
export const getSinglePoolService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: poolEndpoints.singlePool.v1, query, pathParams });
export const getClientListService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: poolEndpoints.clientList.v1, query, pathParams });
export const getDriverListService = ({ query, pathParams }) =>
  callApi({ uriEndPoint: poolEndpoints.driverList.v1, query, pathParams });

export const getPool = ({ query }) => callApi({ uriEndPoint: poolEndpoints.getPool.v1, query });

export const deletePoolService = ({ pathParams }) => callApi({ uriEndPoint: poolEndpoints.deletePool.v1, pathParams });
export const updateDriverPool = ({ body }) => callApi({ uriEndPoint: poolEndpoints.updateDriverPool.v1, body });
export const deleteDriverFromPool = ({ pathParams }) =>
  callApi({ uriEndPoint: poolEndpoints.deleteDriverFromPool.v1, pathParams });
export const addClientToPoolService = ({ pathParams, body }) =>
  callApi({ uriEndPoint: poolEndpoints.addClientToPool.v1, pathParams, body });
export const removeClientFromPoolService = ({ pathParams }) =>
  callApi({ uriEndPoint: poolEndpoints.removeClientFromPool.v1, pathParams });
export const sendEmail = ({ body }) => callApi({ uriEndPoint: poolEndpoints.sendEmail.v1, body });
